import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import getAxios from "services/axios-get";
import putAxios from "services/axios-put";
import postAxios from "services/axios-post";
import deleteAxios from "services/axios-delete";

import EditCustomerCarListing from "./EditCustomerCarListing";
import DatetimePicker from "vuetify-datetime-picker";

Vue.use(VueAxios, axios);
import moment from "moment";

Vue.use(VueAxios, axios, moment);

Vue.use(DatetimePicker);
export default {
  components: {
    EditCustomerCarListing,
  },
  data() {
    return {
      pageSizeList: [10, 20, 50, 100],
      nameSearch: "",
      customerNameSearch: "",
      carPlateSearch: "",
      statusFilter: "",
      usedCar: [],
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Name", value: "productTitle" },
        { text: "Customer", value: "customerInfoName" },
        { text: "ID Type", value: "idType" },
        { text: "ID Number", value: "idNum" },
        { text: "Car Plate", value: "carPlateNumber" },
        { text: "Car Brand | Model | Submodel", value: "carBrandName" },
        { text: "Model Year", value: "carModelYear" },
        { text: "Reg. Date", value: "regDate" },
        { text: "Price", value: "salePrice" },
        { text: "Depre", value: "depreciation" },
        { text: "Mileage", value: "mileage" },
        { text: "Images", value: "defaultDisplayImg", sortable: false },
        { text: "Status", value: "status" },
        { text: "Edit", sortable: false },
        { text: "Bidding", sortable: false },
        { text: "Delete", sortable: false },
        { text: "Id", value: "id" },
      ],
      carStatusList: [
        { text: "PENDING BIDDING", value: "PENDING_BIDDING" },
        { text: "BIDDING", value: "BIDDING" },
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "DEALER ACCEPTED", value: "DEALER_ACCEPTED" },
        { text: "REJECTED", value: "REJECTED" },
        { text: "SOLD", value: "SOLD" },
      ],
      delListingCar: {
        id: "",
        name: "",
      },
      editListingCarStatus: {
        carId: "",
        status: "",
      },
      snackMessage: "",
      snackbar: false,
      deleteDialog: false,
      editStatusDialog: false,
      customerCarList: [],
      coeTypeList: [],
      vesCategoryList: [],
      coeCatList: [],
      idTypeList: [],
      biddingEdit: false,
      biddingCar: {
        bidStartPrice: 0,
        biddingScheduleId: "",
        carId: "",
        contactNum: "",
        editorNotes: "",
        expectPrice: 0,
        handoverDate: "",
        inspected: "",
        goldPrice: 0,
        ownerName: "",
        paperValue: 0,
        bidType: "AUTOBID",
      },
      scheduleBidding: {
        bidStartTime: "",
        bidEndTime: "",
      },
      sortValue: null,
      sortBy: null,
      sortDesc: null,
      bidTypeSelection: [
        { value: "AUTOBID", name: "AUTOBID" },
        { value: "CLOSEBID", name: "CLOSEBID" },
      ],
      type: null,
    };
  },
  mounted() {
    this.fetchIDType();
    this.fetchCarListing();
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName;
      if (sortBy[0] === "productTitle") {
        columnName = "car.productTitle";
      } else if (sortBy[0] === "carModelYear") {
        columnName = "car.carModel.modelYear";
      } else if (sortBy[0] === "salePrice") {
        columnName = "car.salePrice";
      } else if (sortBy[0] === "carBrandName") {
        columnName = "car.carModel.name";
      } else if (sortBy[0] === "status") {
        columnName = "car.status";
      } else if (sortBy[0] === "customerInfoName") {
        columnName = "car.customer.name";
      } else {
        columnName = sortBy[0];
      }
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = "car.createTime,DESC";
      }
      this.fetchCarListing();
    },
    convertDate(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD");

      return parsedDate;
    },
    convertDateTime(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD HH:mm");

      return parsedDate;
    },
    delButtonClick(item) {
      this.delListingCar.id = item.id;
      this.delListingCar.name = item.productTitle;
      this.deleteDialog = true;
    },
    doDelListingCar() {
      const self = this;
      const param = {
        carId: self.delListingCar.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/delCarUsed`,
        param,
        self.token
      ).then(res => {
        if (res.data) {
          self.deleteDialog = false;
          self.fetchCarListing();
          self.showSnack("Delete Car Successfully", "success");
        }
      });
    },
    searchType() {
      this.fetchCarListing();
    },
    add() {
      this.fetchCarListing();
      this.showSnack("Add Car Successfully", "success");
    },
    edit() {
      this.fetchCarListing();
      this.showSnack("Edit Car Successfully", "success");
    },
    paginationChangeHandler() {
      this.fetchCarListing();
    },
    fetchIDType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/idTypePair`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.idTypeList = res.data.data.content;
        }
      });
    },
    fetchCarListing() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        productTitle: self.nameSearch,
        carStatus: self.statusFilter,
        customerName: self.customerNameSearch,
        carPlate: self.carPlateSearch,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/customerUsedCars`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.customerCarList = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(e => {
          const errMsg = e.response.data.message;
          self.showSnack("List Car Failed " + errMsg, "error");
        });
    },
    editStatusButtonClick(item) {
      this.editListingCarStatus.carId = item.id;
      this.editListingCarStatus.status = item.status;
      this.editStatusDialog = true;
    },
    showSnack(msg, color) {
      this.snackMessage = msg;
      this.color = color;
      this.snackbar = true;
    },
    async doEditListingCarStatus() {
      const self = this;
      try {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/updPartnerCarStatus?carId=` +
            self.editListingCarStatus.carId +
            "&status=" +
            self.editListingCarStatus.status,
          self.token
        )
          .then(res => {
            if (res.data.httpStatus === "OK") {
              self.editStatusDialog = false;
              self.fetchCarListing();
              self.showSnack("Edit Car Status Successfully", "success");
            }
          })
          .catch(error => {
            self.showAlert("error", error.response.data.message);
          });
      } catch (e) {
        self.showSnack("Edit Car Failed" + e, "error");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    bidButtonClick(item) {
      this.biddingCar.carId = item.id;
      this.biddingCar.editorNotes = item.notes;
      this.biddingCar.paperValue = item.scrapValue;
      this.biddingEdit = true;
    },
    async doBidUsedCar() {
      const self = this;
      try {
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/biddingSystem/addBiddingSchedule`,
          self.scheduleBidding,
          self.token
        )
          .then(res => {
            if (res.data.httpStatus === "OK") {
              self.biddingCar.biddingScheduleId = res.data.data;
              postAxios(
                `${process.env.VUE_APP_SERVER_URL}/biddingSystem/addBidding`,
                self.biddingCar,
                self.token
              )
                .then(res => {
                  if (res.data.httpStatus === "OK") {
                    self.biddingEdit = false;
                    self.fetchUsedCar();
                    self.showSnack(
                      "Car Successfully Send For Bidding",
                      "success"
                    );
                  }
                })
                .catch(error => {
                  self.showAlert("error", error.response.data.message);
                });
            }
          })
          .catch(error => {
            self.showAlert("error", error.response.data.message);
          });
      } catch (e) {
        console.error(e);
        self.showSnack("Add Bidding Failed" + e, "error");
      }
    },
  },
};
