import axios from "axios";
import helper from "./helper";

import objectToFormData from "../utils/objectToFormData";

const uploadFile = file => {
  const { size } = file;
  if (size > 500000000) {
    throw new Error("Image size has to be < 500 MB");
  }

  const options = {
    headers: {
      Authorization: `Bearer ` + localStorage.getItem("jwt"),
      "c-time": helper.getDateTime(),
      "x-api-key": helper.sha256(
        process.env.VUE_APP_API_KEY + helper.getDateTime()
      ),
    },
  };
  return axios.post(
    `${process.env.VUE_APP_SERVER_URL}/file/uploadAuctionPdf`,
    objectToFormData({
      file,
    }),
    options
  );
};

export default uploadFile;
